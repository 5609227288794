import React from "react"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Amplify, { API } from 'aws-amplify';
import Helmet from "react-helmet"
//import { searchVideotimestamps } from '../components/graphqlOps'
import { listVideotimestamps } from '../graphql/queries'
import { GoSearch } from "react-icons/go";

import awsconfig from '../config';

//import "bulma/css/bulma.min.css"
//import "~bulma/bulma.sass";
import bulma from '../css/bulma.module.css'
import custom from '../css/custom.module.css'

//import "./mystyles.scss"
//import '../css/featherlight.min.css';
//import '../css/animate.css';
//import '../css/tidy.css';

//require('typeface-roboto');

Amplify.configure(awsconfig);
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

const Logo = () => (
<StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "vt-icon.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 125, height: 125) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
render={data => (
   <div>
    <Img fixed={data.file.childImageSharp.fixed} />
  </div>
)}
/>
)

const NavBar = (props) => (
<nav className={bulma.navbar} role="navigation" aria-label="main navigation" style={{backgroundColor: '#e5e5e5'}}>
  <div className={bulma.navbarBrand}>
    <a role="button" className={`${bulma.navbarBurger} ${props.active ? bulma.isActive : ''}`} aria-label="menu" aria-expanded="false"
      onClick={props.toggleMenu} href="/#"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" className={`${bulma.navbarMenu} ${props.active ? bulma.isActive : ''}`} style={{backgroundColor: '#e5e5e5'}}>

    <div className={bulma.navbarEnd}>
      <div className={bulma.navbarItem}>
        <div className={bulma.buttons} style={{justifyContent: 'center'}}>
          <a className={`${bulma.button} ${bulma.isLight}`} href="https://chrome.google.com/webstore/detail/video-timestamps/lcaajcnacljidbdlcalnfhckalkohbej" target="_blank" rel="noopener noreferrer">
            Get The Browser Extension
          </a>
          <a className={`${bulma.button} ${bulma.isLight}`} href="/blog" target="_blank" rel="noopener noreferrer">
            Blog
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
)

export default class Search extends React.Component {

constructor(props) {
    super(props);
    this.state = {
      disableButton: false, activeMenu: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.buildSearchResult = this.buildSearchResult.bind(this);
}  

toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  };

handleChange = e => this.setState({ [e.target.name]: e.target.value })

  async handleSearch(event, tag) {
  	
      event.preventDefault();
      event.stopPropagation();
    

      this.setState({searchMessage: '', disableButton: true})

      if(!this.state.search){
        this.setState({searchMessage: 'Empty search query.', disableButton: false})
        return
      }

      //let search = '';
      
 	if(!tag) //if there is no hashtag to search for
      {
	      let result = null;
	      try {
	        //result = await API.graphql(graphqlOperation(listVideotimestamps, {filter: {searchField: { contains: this.state.search.toLowerCase()}}, limit: 1000} ));
	        result = await API.graphql({
	          query: listVideotimestamps,
	          variables: {filter: {searchField: { contains: this.state.search.toLowerCase()}}, limit: 1000},
	          authMode: "API_KEY"
	        });

	        this.setState({searchResults:result.data.listVideotimestamps.items, displaySearch:true /*search:''*/});   

	        if(JSON.stringify(result.data.listVideotimestamps.items) === '[]'){
	          this.setState({searchMessage: 'No video timestamps found.'});
	        };
	      }
	      catch(err){
	        console.log(err)
	        this.setState({searchMessage: 'No video timestamps found.', displaySearch:true, /*search:''*/});  
	      }
	  }
	  else{ //if there is a hashtag
	  	this.setState({search: tag})
	  	let result = null;
	      try {
	        //result = await API.graphql(graphqlOperation(listVideotimestamps, {filter: {tags: { contains: tag}}, limit: 1000} ));
	        result = await API.graphql({
	          query: listVideotimestamps,
	          variables: {filter: {tags: { contains: tag}}, limit: 1000},
	          authMode: "API_KEY"
	        });
	        this.setState({searchResults:result.data.listVideotimestamps.items, displaySearch:true /*search:''*/});   
		    
		    if(JSON.stringify(result.data.listVideotimestamps.items) === '[]'){
	          this.setState({searchMessage: 'No video timestamps found.'});
	        };
	      }
	      catch(err){
	        console.log(err)
	        this.setState({searchMessage: 'No video timestamps found.', displaySearch:true, /*search:''*/});  
	      }
	  }    
	  this.setState({disableButton: false}) 
}      

buildSearchResult(timestamps, videourl){
  let result = []
  let counter = 0
  for(let i = 0; i < timestamps.length; i++){
    if(counter === 3){
      counter = 0
      return result.map(x => x)
    }
    else {  
      let x = timestamps[i]
      if(x && x.toLowerCase().includes(this.state.search.toLowerCase()))
      {
        result.push(<React.Fragment key={i}><span key={`linkspan-${i}`}>
        <a key={`link-${i}`} href={`https://www.youtube.com/watch?v=${videourl}&t=${x.split('::')[0].split(':')[0]}h${x.split(' ')[0].split(':')[1]}m${x.split(' ')[0].split(':')[2]}s`}
        target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}
        >
       {x.replace("::", " ")}
       </a>{" "}</span><br key={`break-${i}`}/></React.Fragment>)
       counter++ 
      }
    
    }  
  }

  if(result.length === 0){
    let x = timestamps[0]
    result.push(<React.Fragment key="0"><span key="linkspan-0">
        <a key="link-0" href={`https://www.youtube.com/watch?v=${videourl}&t=${x.split('::')[0].split(':')[0]}h${x.split(' ')[0].split(':')[1]}m${x.split(' ')[0].split(':')[2]}s`}
        target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}
        >
       {x.replace("::", " ")}
       </a>{" "}</span><br key="break-0"/></React.Fragment>)
  }
  return result.map(x => x)
}

ListVideoTimestamps = (props) => (
  <>
    <section className={bulma.section} style={{paddingTop: '2rem'}}>
      <div className={bulma.container}>
        <div className={bulma.content}> 

        {props.timestamps && props.timestamps.map((t, i) =>
        <div className={`${bulma.card} ${bulma.hasMarginBottom20}`} key={"card-" + i}>
          
          <div className={bulma.cardContent}>
          <div className={custom.searchResult}>
            <div>
                <img src={`https://img.youtube.com/vi/${t.videourl}/default.jpg`} alt={t.title} style={{paddingBottom: '10px'}}/>
            </div>

            <div style={{display: 'inline-block', verticalAlign: 'top'}}>
              <div style={{marginLeft: '20px'}}>
                <p className={`${bulma.title} ${bulma.is6}`}>{t.title}</p>
              </div>
            
              <div className={bulma.content} style={{marginLeft: '20px', marginTop: '10px'}}>
              { 
                  t.description && this.buildSearchResult(t.description.split(';;'), t.videourl)
              }
              <br/>
              { 
                  t.tags && t.tags.split(',').map( (x, i) =>
                      x && <span className={bulma.tag} style={{marginRight: '10px'}} key={`tag-${i}`}>
                      <a key={`taglink-${i}`} href="/#" onClick={(e) => this.handleSearch(e, x)}>#{x}</a>{" "}
                      </span>
                  )
              }
              <br/>

              </div>

            </div>
          </div>
        </div>  
        </div>  
        )}   
       
      </div>    
     </div>
    </section>
  </>
)

render(){
  return(  
    <>
    <Helmet>
      <body className={custom.body}/>
    </Helmet>
    <SEO title="Video Timestamps Search Engine & Browser Extension" />

    <NavBar 
      active={this.state.activeMenu}
      toggleMenu={this.toggleMenu}
    />

    <div style={{backgroundColor: '#e5e5e5'}}>
    <section className={bulma.section} style={{paddingTop: '1rem'}}>
    <div className={bulma.container}>
      <div className={`${bulma.content} ${bulma.isVcentered} ${bulma.hasTextCentered}`}>
        <div style={{marginBottom: '20px'}}>
          <Logo /> Video Timestamps Search Engine
        </div>
        <input className={`${bulma.input} ${bulma.isRounded}`} type="text" name="search" placeholder="" value={this.state.search || ''} onChange={this.handleChange} onKeyDown={(e) => {if(e.keyCode === 13) this.handleSearch(e, null)}} />
        {" "}
        <button disabled={this.state.disableButton} className={`${bulma.button} ${bulma.isNormal} ${bulma.isRounded}`} onClick={(e) => {this.handleSearch(e, null)}} style={{marginTop: '10px', paddingLeft: '2em', paddingRight: '2em'}}>
        	<GoSearch style={{marginRight: '10px'}}/> Search
        </button>
      </div>
    </div>
    </section> 

    { this.state.searchResults && this.state.searchResults.length > 0 ?
      <this.ListVideoTimestamps timestamps={this.state.searchResults} />
      :
      <section className={bulma.section}>
        <div className={`${bulma.container} ${bulma.hasTextCentered}`}>
          <div style={{margin: 'auto'}}>{this.state.searchMessage}</div>
        </div>
      </section>
    }

    </div>
        
    </>  
)}

}


const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.GATSBY_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {},
    "aws_appsync_graphqlEndpoint": process.env.GATSBY_AWS_APPSYNC_GRAPHQLENDPOINT,
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": process.env.GATSBY_AWS_APPSYNC_APIKEY
};


export default awsmobile;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVideotimestamp = /* GraphQL */ `
  query GetVideotimestamp($id: ID!) {
    getVideotimestamp(id: $id) {
      id
      username
      videourl
      title
      description
      tags
      playlist
      createdAt
      owner
    }
  }
`;
export const listVideotimestamps = /* GraphQL */ `
  query ListVideotimestamps(
    $filter: ModelVideotimestampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideotimestamps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        videourl
        title
        description
        tags
        playlist
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getTimestampsByDate = /* GraphQL */ `
  query GetTimestampsByDate(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideotimestampFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTimestampsByDate(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        videourl
        title
        description
        tags
        playlist
        createdAt
        owner
      }
      nextToken
    }
  }
`;
